import React from "react"
import Layout from "../../components/Layout"
import SEO from "../../components/Seo"
import Banner from "../../components/Common/Banner"
import Menu from "../../components/IELTS_Academic/Menu"
import ListeningModule from "../../components/IELTS_Academic/ListeningModule"
import RecomendedCourses from '../../components/IELTS_Academic/RecomendedCourses'

const testId = 'ab6e795fc82734c2b08e627032f8680e'
const courseId = '695431377ee45b963cebc7fc57eb7669'

const Listening = () => (
  <Layout>
    <SEO title="IELTS Academic - Listening" />
    <main>
        <Banner />
        <Menu />
        <ListeningModule testId={testId} courseId={courseId} />
        <RecomendedCourses testId={testId} courseId={courseId} />
    </main>
  </Layout>
)

export default Listening
