import React from "react"
import LaunchTestButton from "../LaunchTestButton"

const ListeningModule = (props) => (
    <React.Fragment>
    <section className="lrws-section-1">
        <div className="container">
        <div className="listening-wrapper mt-5">
              <div className="row">
                <div className="col-md-4">
                  <div className="listening-image-wrapper">
                    <img src={require('../../images/listening-image.png')} alt="" />
                  </div>
                </div>
                <div className="col-md-8" >
                  <div className="listening-content-wrapper">
                    <h3 className="content-title">
                      Listening
                    </h3>
                    <ul>
                      <li>
                        <span>
                          <img src={require('../../images/questions.png')} alt="" />
                          <strong>Questions : </strong> 30 min (plus 10 min transfer time)
                        </span>
                      </li>
                      <li>
                        <span>
                          <img src={require('../../images/duration.png')} alt="" />
                          <strong>Duration : </strong> 40
                        </span>
                      </li>
                    </ul>
                    <p className="note">The listening part has four sections. </p>
                    <div className="listening-sections-wrapper">
                      <p className="content-sub-title">Sections</p>
                      <div className="content-sections">
                        <div>Section 1</div>
                        <p>a conversation between two people set in an everyday social context</p>
                      </div>
                      <div className="content-sections">
                        <div>Section 2</div>
                        <p>a monologue set in an everyday social context, e.g. a speech about local facilities.</p>
                      </div>
                      <div className="content-sections">
                        <div>Section 3</div>
                        <p>a conversation between up to four people set in an educational or trainingcontext, e.g. a university tutor and a student discussing an assignment.</p>
                      </div>
                      <div className="content-sections">
                        <div>Section 4</div>
                        <p>a monologue on an academic subject, e.g. a university lecture.</p>
                      </div>
                    </div>
                    <p className="content-disclaimer">Each section has 10 questions. Each item is worth one mark.</p>
                    <div className="d-flex align-items-center">
                      <LaunchTestButton testId = {props.testId} courseId = {props.courseId} classList="trymock-btn btn" text="TRY MOCK TEST" />
                      <div className="free-label">FREE!</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
)

export default ListeningModule
